const animatedElements = document.querySelectorAll('[data-aod_name]');

const options = {
    root: null,             // null means viewport
    threshold: 0,           // range is 0-1 and it's equivalent to 0-100% of presence of the object on the screen
    rootMargin: "-100px"    // works like css margin, but remember about [px] or [%]
}

const aod_observer = new IntersectionObserver( function( entries, aod_observer ) {
    entries.forEach(entry => {
        if (!entry.isIntersecting) {
            return;
        } else {
            entry.target.style.animation = `${entry.target.dataset.aod_name} ${entry.target.dataset.aod_duration} ${entry.target.dataset.aod_delay} forwards ${entry.target.dataset.aod_tfunc}`
            aod_observer.unobserve(entry.target);
        }
    })
}, options )


animatedElements.forEach(animatedElement => {
    aod_observer.observe(animatedElement);
})